import React from 'react';
import { Login } from '@phpcreation/frontend-components-crud-react'

type Props = {
  cycle: string | undefined;
};

const IndexLogin = (props: Props) => {
  return (
    <Login
      sampleResource="tenants"
      fqcn_bui={{ Bundle: 'securityBundle', Unit: 'login', Interface: 'login' }}
      devUsername="admin"
      devPassword="phpc"
      cycle={props.cycle}
    />
  );
};

export function getStaticProps() {
  const cycle = process.env.npm_lifecycle_event;

  return {
    props: {
      cycle
    }
  }
}

export default IndexLogin;
